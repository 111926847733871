import * as React from "react"
import { Global, css } from "@emotion/react"
import styled from "@emotion/styled"

import '../css/typography.css'
const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
`

const mainLayout = css`
  display: flex;
  flex-direction: column;
`

const Layout: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <Global
        styles={css`
          body {
            background: #c0f3d4;
            color: #000;
            font-family: "HK Grotesk", georgia, serif;
            font-weight: normal;
            word-wrap: break-word;
            font-kerning: normal;
            margin: 0;
          }

          h1 {
            font-family: "HK Grotesk Bold", georgia, serif;
            font-size: 67.5px;
          }

          h2 {
            font-family: "HK Grotesk Bold", georgia, serif;
            font-size: 44px;
          }

          h3 {
            font-family: "HK Grotesk Medium", georgia, serif;
            font-size: 42px;
          }

          h4 {
            font-family: "HK Grotesk Medium", georgia, serif;
            font-size: 26.7px;
          }

          h5 {
            font-family: "HK Grotesk Light", georgia, serif;
            font-size: 28px;
          }

          h6 {
            font-family: "HK Grotesk Light", georgia, serif;
            font-size: 22px;
          }

          p {
            font-family: "HK Grotesk Light", georgia, serif;
            font-size: 16px;
          }
        `}
      />
      <main css={mainLayout}>
        {children}
      </main>
    </Wrapper>
  )
}

export default Layout